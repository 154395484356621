import React from "react";

const TermsItem = ({ title, isPage, slug, description, abbreviation }) => {
  return (
    <div>
      {isPage == "No" ? (
        <div>
          {" "}
          <div class="mb-10">
            <a class="">
              <li class="mr-6 lg:mr-0 col-span-1 flex shadow-sm rounded-md">
                <div class="flex-shrink-0 flex items-center justify-center w-16 bg-blue-600 text-white text-sm leading-5 font-medium rounded-l-md">
                  {abbreviation}
                </div>
                <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md">
                  <div class="flex-1 px-4 py-2 text-sm leading-5">
                    <div class="mt-4 text-lg text-gray-900 font-medium hover:text-gray-600 transition ease-in-out duration-150">
                      {title}
                    </div>
                    <p class="text-gray-500 font-light">{description}</p>
                  </div>
                </div>
              </li>
            </a>
          </div>
        </div>
      ) : (
        <div>
          {" "}
          <div class="mb-10 hover:opacity-75">
            <a href={slug}>
              <li class="mr-6 lg:mr-0 col-span-1 flex shadow-sm rounded-md">
                <div class="flex-shrink-0 flex items-center justify-center w-16 bg-green-400 text-white text-sm leading-5 font-medium rounded-l-md">
                  {abbreviation}
                </div>
                <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md">
                  <div class="flex-1 px-4 py-2 text-sm leading-5">
                    <div class="mt-4 text-lg text-gray-900 font-medium hover:text-gray-600 transition ease-in-out duration-150">
                      {title}
                    </div>
                    <p class="text-gray-500 font-light">{description}</p>
                    <p class="font-medium text-green-700 font-light">
                      Read More
                    </p>
                  </div>
                </div>
              </li>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default TermsItem;
